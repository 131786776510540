import { HStack } from "@chakra-ui/layout";
import carCollisionIcon from "../assets/map/car-collision.png";
const { Button } = require("@chakra-ui/button");
const { Image } = require("@chakra-ui/image");


const ScenarioButton = (props) => {

    return (
        <Button placeContent="baseline" px={1} _hover={{bg:"orange.500"}} _focus={{outline:"none"}} {...props}>

            
            <Image float="right" ml={1} mr={2} height="50%" src={carCollisionIcon}></Image>
            {props.children}

           
            
        </Button>
    );
};

export default ScenarioButton;