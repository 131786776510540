const prepareMarkerTooltipForCarA = (speed) => {
    return `<b>Speed:</b> `+speed+`km/hr<br><b>License Plate:</b> DL00XX1234`;
}
const prepareMarkerTooltipForCarB = (speed) => {
    return `<b>Speed:</b> `+speed+`km/hr<br><b>License Plate:</b> DL00XX4567`;
}

const setTimeOutToUpdateMarkerTooltip = (carA, carB, speedA, speedB, time) => {
    setTimeout(() => {
        carA.getMarker().setTooltipContent(prepareMarkerTooltipForCarA(speedA));
        carB.getMarker().setTooltipContent(prepareMarkerTooltipForCarB(speedB));
    }, time);
}

export {setTimeOutToUpdateMarkerTooltip}