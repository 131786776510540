import yellowAirplaneIcon from 'assets/map/static/yellow_airplane.svg';
import blueAirplaneIcon from 'assets/map/static/blue_airplane.svg';


const { movingPlaneIconWithIdAndRotation, movingFireTruckIconWithIdAndRotation } = require("./markers");

const L = window.L;
var dataForVehicleOne= [];
var dataForVehicleTwo= [];
var dataForVehicleThree= [];
var vehicleOneTimeout, vehicleTwoTimeout, vehicleThreeTimeout;

var vehicleOne, vehicleTwo, vehicleThree;

const loadInfiniteMotionGeoJSON = (map)=>{
    new L.GeoJSON.AJAX("/json/Infinite-Loop-1.geojson",{onEachFeature:function (feature, layer) {
        dataForVehicleOne.push(layer.getLatLngs());
        startMotionForVehicleOne(map);
      }});
    
    new L.GeoJSON.AJAX("/json/Infinite-Loop-2.geojson",{onEachFeature:function (feature, layer) {
        dataForVehicleTwo.push(layer.getLatLngs());
        startMotionForVehicleTwo(map);
      }
    });

    new L.GeoJSON.AJAX("/json/Infinite-Loop-3.geojson",{onEachFeature:function (feature, layer) {
        dataForVehicleThree.push(layer.getLatLngs());
        startMotionForVehicleThree(map);
      }});
   
}

const startMotionForVehicleOne = (map)=>{
    vehicleOne = L.motion.polyline(dataForVehicleOne[0], {
        color: "transparent",
        markerId: "infinite-loop-1-marker",
        rotation: 130,
        type: "airplane"
      }, {
        auto: true,
        easing: L.Motion.Ease.swing,
      },{icon:movingPlaneIconWithIdAndRotation("infinite-loop-1-marker",130, getZoomMultiplier(map))}).motionDuration(35000).addTo(map);
    vehicleOne.once(L.Motion.Event.Ended, (e) => {
        vehicleOne.off();
        map.removeLayer(vehicleOne);
        startMotionForVehicleOne(map);
      });
      vehicleOneTimeout = setTimeout(()=>{
       rotateMarker("infinite-loop-1-marker",45);
       vehicleOne.options.rotation = 45;
       vehicleOneTimeout = setTimeout(()=>{
           rotateMarker("infinite-loop-1-marker",-50);
              vehicleOne.options.rotation = -50;
            vehicleOneTimeout = setTimeout(()=>{
                rotateMarker("infinite-loop-1-marker",45);
                vehicleOne.options.rotation = 45;
                vehicleOneTimeout = setTimeout(()=>{
                    rotateMarker("infinite-loop-1-marker",130);
                    vehicleOne.options.rotation = 130;
                },4700);
            },11000);
        },4700);
    },7500);
      
}

const startMotionForVehicleTwo = (map)=>{
    vehicleTwo = L.motion.polyline(dataForVehicleTwo[0], {
        color: "transparent",
        markerId: "infinite-loop-2-marker",
        rotation: 130
      }, {
        auto: true,
       // easing: L.Motion.Ease.swing,
      },{icon:movingPlaneIconWithIdAndRotation("infinite-loop-2-marker",130,getZoomMultiplier(map))}).motionDuration(40000).addTo(map);
    vehicleTwo.once(L.Motion.Event.Ended, (e) => {
        vehicleTwo.off();
        map.removeLayer(vehicleTwo);
        startMotionForVehicleTwo(map);
      });
    vehicleTwoTimeout = setTimeout(()=>{
        rotateMarker("infinite-loop-2-marker",45);
        vehicleTwo.options.rotation = 45;
        vehicleTwoTimeout = setTimeout(()=>{
            rotateMarker("infinite-loop-2-marker",-50);
            vehicleTwo.options.rotation = -50;
            // vehicleTwoTimeout = setTimeout(()=>{
            //     rotateMarker("infinite-loop-2-marker",45);
            //     vehicleTwo.options.rotation = 45;
            //     vehicleTwoTimeout = setTimeout(()=>{
            //         rotateMarker("infinite-loop-2-marker",-45);
            //         vehicleTwo.options.rotation = -45;
            //         vehicleTwoTimeout = setTimeout(()=>{
            //             rotateMarker("infinite-loop-2-marker",-135);
            //             vehicleTwo.options.rotation = -135;
                        vehicleTwoTimeout = setTimeout(()=>{
                            rotateMarker("infinite-loop-2-marker",-45);
                             vehicleTwo.options.rotation = -45;
                            vehicleTwoTimeout = setTimeout(()=>{
                             rotateMarker("infinite-loop-2-marker",-135);
                             vehicleTwo.options.rotation = -135;
                             vehicleTwoTimeout = setTimeout(()=>{
                                 rotateMarker("infinite-loop-2-marker",-225);
                                 vehicleTwo.options.rotation = -225;
                                 vehicleTwoTimeout = setTimeout(()=>{
                                     rotateMarker("infinite-loop-2-marker",-135);
                                     vehicleTwo.options.rotation = -135;
                                     vehicleTwoTimeout = setTimeout(()=>{
                                         rotateMarker("infinite-loop-2-marker",-45);
                                         vehicleTwo.options.rotation = -45;
                                     },1900);
                                 },12600);
                             },1200);
                            },11100);
                        },2300);
            //         },200);
            //     },2300);
            // },4500);
        },3300);
    },3200);

      
}

const startMotionForVehicleThree = (map)=>{
    vehicleThree = L.motion.polyline(dataForVehicleThree[0], {
        color: "transparent",
        markerId: "infinite-loop-3-marker",
        rotation: 220
      }, {
        auto: true,
        easing: L.Motion.Ease.easeInQuad,
      },{icon:movingPlaneIconWithIdAndRotation("infinite-loop-3-marker",220,getZoomMultiplier(map))}).motionDuration(35000).addTo(map);
    vehicleThree.once(L.Motion.Event.Ended, (e) => {
        vehicleThree.off();
        map.removeLayer(vehicleThree);
        startMotionForVehicleThree(map);
      });

    vehicleThreeTimeout = setTimeout(()=>{
        updateMarkerRotation("infinite-loop-3-marker",90,"R",vehicleThree);
        vehicleThreeTimeout = setTimeout(()=>{
            updateMarkerRotation("infinite-loop-3-marker",90,"R",vehicleThree);
            vehicleThreeTimeout = setTimeout(()=>{
                updateMarkerRotation("infinite-loop-3-marker",90,"L",vehicleThree);
                vehicleThree.motionPause();
                document.getElementById("infinite-loop-3-marker").src = yellowAirplaneIcon;
                vehicleThreeTimeout = setTimeout(()=>{
                    document.getElementById("infinite-loop-3-marker").src = blueAirplaneIcon;
                    vehicleThree.motionResume();
                    updateMarkerRotation("infinite-loop-3-marker",5,"L",vehicleThree);

                    vehicleThreeTimeout = setTimeout(()=>{
                        updateMarkerRotation("infinite-loop-3-marker",5,"R",vehicleThree);
                        vehicleThreeTimeout = setTimeout(()=>{
                            updateMarkerRotation("infinite-loop-3-marker",90,"R",vehicleThree);
                            vehicleThreeTimeout = setTimeout(()=>{
                                updateMarkerRotation("infinite-loop-3-marker",5,"L",vehicleThree);
                                vehicleThreeTimeout = setTimeout(()=>{
                                    updateMarkerRotation("infinite-loop-3-marker",5,"L",vehicleThree);
                                },4000);
                            },4000);
                        },700);
                    },1000);
                },5000);
            },3500);
        },11500);
    },5000);
    
}


const getZoomMultiplier = (map) => {
    if(map.getZoom()>=18){
      return 1.75;
    }
    else if(map.getZoom()>=17){
      return 1.25;
    }
    else if(map.getZoom()>=15){
      return 1;
    }
    else if(map.getZoom()>=14){
      return 0.8;
    }
    return 1;
  }
const hideLoopLayers = (map)=>{
    if(vehicleOneTimeout)
    clearTimeout(vehicleOneTimeout);
    if(vehicleTwoTimeout)
    clearTimeout(vehicleTwoTimeout);
    if(vehicleThreeTimeout)
    clearTimeout(vehicleThreeTimeout);
    vehicleOne.off();
    vehicleTwo.off();
    vehicleThree.off();
    vehicleOne.remove();
    vehicleTwo.remove();
    vehicleThree.remove();
    vehicleOne = undefined;
    vehicleTwo = undefined;
    vehicleThree = undefined;
};

const showLoopLayers = (map)=>{
    if(!vehicleOne)
    startMotionForVehicleOne(map);
    if(!vehicleTwo)
    startMotionForVehicleTwo(map);
    if(!vehicleThree)
    startMotionForVehicleThree(map);
}

const updateMarkerRotation = (markerId,angle,turn,layer)=>{
    var currentRotationDegrees = parseInt(document.getElementById(markerId).style.transform.split('(')[1].split('deg')[0]);
    if(turn === "R"){
        currentRotationDegrees += angle;
    }else{
        currentRotationDegrees -= angle;
    }

    layer.options.rotation = currentRotationDegrees;
    document.getElementById(markerId).style.transform = "rotate("+currentRotationDegrees+"deg)";
}


const rotateMarker = (markerId,angle)=>{
    let marker = document.getElementById(markerId);
    marker.style.transform = `rotate(${angle}deg)`;
}

export {loadInfiniteMotionGeoJSON, hideLoopLayers, showLoopLayers};