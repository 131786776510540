import carCollisionIcon from "../../assets/map/car-collision.png";

const collisionModal = {
    id:1,
    vehicleOnePlate:'DL00XX1234',
    vehicleTwoPlate:'DL00XX4567',
    atFault: 1,
    timestamp: '2021-12-06T00:00:00.000Z',
    location: "Airstrip 1"

}

var collision = [
    {...collisionModal, id:1,timestamp: '2021-12-05T00:00:00.000Z'},
    {...collisionModal, id:2, timestamp: '2021-12-06T00:00:00.000Z'},
];

const template = (collision)=>{

    var timestamp = new Date(collision.timestamp);
    //Format time as mm-dd-yyyy hh:mm:ss
    var time = timestamp.getMonth()+"-"+timestamp.getDate()+"-"+timestamp.getFullYear()+" "+timestamp.getHours()+":"+timestamp.getMinutes()+":"+timestamp.getSeconds();

    return `
    <div class="crash-detail">
                    <span class="crash-detail-label-text">Collision ID: </span> `+collision.id+`
                    <br/>
                    <span class="crash-detail-label-text">Date &amp; Time: </span> `+time+`
                    <br/>
                    <span class="crash-detail-label-text">Vehicle 1 License Plate: </span> `+collision.vehicleOnePlate+`
                    <br/>
                    <span class="crash-detail-label-text">Vehicle 2 License Plate: </span> `+collision.vehicleTwoPlate+`
                    <br/>
                    <span class="crash-detail-label-text">At Fault: </span> `+collision.atFault+`
                    <br/>
                    <span class="crash-detail-label-text">Location: </span> `+collision.location+`
                </div>`;
}

const prepareSidebarContent = ()=>{
    var content = `<h5 class="sidebar-heading"><img src="`+carCollisionIcon+`" style="height:30px; margin-right:10px;"/>Vehicle Collision - Taxiway History</h5>
    <div class="crash-details-grid">`;
    [...collision].reverse().forEach(collision=>{
        content += template(collision);
    });
    
    content += `</div>`;
    return content;

}

const addCollision = ()=>{
    collision.push({...collisionModal, id:collision.length+1, timestamp: new Date().toISOString()});
}

export {prepareSidebarContent,addCollision};