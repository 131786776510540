import greyAirplaneIcon from 'assets/map/static/grey_airplane.svg';
import redAirplaneIcon from 'assets/map/static/airplane-red.svg';
import redBlueAirplaneIcon from 'assets/map/static/airplane-red-blue.svg';
import blueAirplaneIcon from 'assets/map/static/blue_airplane.svg';
import fireTruckBlueIcon from 'assets/map/static/fire-truck-blue.svg';
import yellowAirplaneIcon from 'assets/map/static/yellow_airplane.svg';

const L = window.L;
const movingPlaneIcon = L.divIcon({html: "<img class='collision-icon' src='"+ blueAirplaneIcon +"'/>", 
    iconSize: [40, 40],
});

const movingPlaneIconWithIdAndRotation = (id,deg,multiply) => L.divIcon({html: "<img class='collision-icon' src='"+ blueAirplaneIcon +"' id='"+id+"' style='transform: rotate("+deg+"deg)'/>", 
    iconSize: [40*multiply, 40*multiply],
});

const movingPlaneSpeedViolationIconWithIdAndRotation = (id,deg,multiply) => L.divIcon({html: "<img class='collision-icon' src='"+ redBlueAirplaneIcon +"' id='"+id+"' style='transform: rotate("+deg+"deg)'/>", 
    iconSize: [40*multiply, 40*multiply],
});

const movingFireTruckIconWithIdAndRotation = (id,deg,multiply) => L.divIcon({html: "<img class='collision-icon' src='"+ fireTruckBlueIcon +"' id='"+id+"' style='transform: rotate("+deg+"deg)'/>", 
    iconSize: [40*multiply, 40*multiply],
});

const stopPlaneIcon = L.icon({
    iconUrl: greyAirplaneIcon,
    iconSize: [40, 40],
});
const collisionPlaneIcon = L.icon({
    iconUrl: redAirplaneIcon,
    iconSize: [40, 40],
});

export {movingPlaneIcon, stopPlaneIcon, collisionPlaneIcon, movingPlaneIconWithIdAndRotation, movingFireTruckIconWithIdAndRotation, movingPlaneSpeedViolationIconWithIdAndRotation};