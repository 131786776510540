import homeIcon from 'assets/map/home.png';
import soundIcon from 'assets/map/sound.png';
import muteIcon from 'assets/map/mute.png';
import tableIcon from 'assets/map/table.png';
import collisionSound from 'assets/audio/crash.mp3';

//Import static icons
import gangwayIcon from 'assets/map/static/gangway-grey.svg';
import fireTruckGreyIcon from 'assets/map/static/fire-truck-grey.svg';
import luggageCarGreyIcon from 'assets/map/static/luggage-car-grey.svg';

import * as turf from '@turf/turf'
import { addCollision, prepareSidebarContent } from './sidebar-control';
import { setTimeOutToUpdateMarkerTooltip } from 'scenarios/scenario-1';
import { collisionPlaneIcon, movingFireTruckIconWithIdAndRotation, movingPlaneIconWithIdAndRotation, movingPlaneSpeedViolationIconWithIdAndRotation, stopPlaneIcon } from './markers';
import { loadInfiniteMotionGeoJSON, showLoopLayers, hideLoopLayers } from './static-motion';
const L = window.L;

//Initial location of Map
const initMapData= {
    lat:33.9415889,
    lon:-118.40853,
    zoom:15
}
var runwayLayer;
const collisionAudio = new Audio(collisionSound);
var collisionConfig = {
  motionDuration: 10000,
  speedA: 12,
  speedB: 15,
}
var sidebar;
//Map Instance
var map;
var soundToggle = true;
var staticDataLayer;
//Other Map Variables
var carRoute = [];
var carA, carB;
var carAFinished = false;
var carBFinished = false;
var carMotionLayer;
var limitAreaLayer;
var collisionLatLng;
var collisionMarkerLayer;
var carATimeout, carBTimeout;
//Initialize Map
const initiateMap = () => {
    if(map){
        return map;
    }
    
    map = L.map('map',{attributionControl:false}).setView([initMapData.lat,initMapData.lon], initMapData.zoom);;
    map.doubleClickZoom.disable(); 
    map.addControl(new homeButton());
    map.addControl(new soundButton());
    map.addControl(new sidebarButton());
    loadRunwayGeoJSON();
    loadCarRouteGeoJSON();
    loadStaticData();
    map.addControl(
       L.control.basemaps({
        basemaps: basemaps,
        tileX: 0, // tile X coordinate
        tileY: 0, // tile Y coordinate
        tileZ: 1, // tile zoom level
      })
    );
    sidebar = L.control.sidebar('sidebar', { position: 'left' });
    map.addControl(sidebar);
    sidebar.setContent(prepareSidebarContent());
    zoomListener();
    loadInfiniteMotionGeoJSON(map);
    return map;
}

//Load Runway GeoJSON
const loadRunwayGeoJSON = () => {
  runwayLayer = new L.GeoJSON.AJAX("/json/LA-Runway-Dissolved.geojson",{style:{color:"rgba(0,0,0,0.75)",weight:2,opacity:0.75}});      

  runwayLayer.addTo(map);
}

const startCarRoute = () => {
  moveToHome();
  resetScenario();
  //Hide sidebar if visible
  if(sidebar.isVisible()){
    sidebar.hide();
  }
   carA = L.motion.polyline(carRoute[0], {
    color: "transparent",
    markerId: "vehicle-A",
    rotation: 35,
    type: "airplane",
  }, {
    auto: true,
    easing: L.Motion.Ease.swing,
  },{icon:movingPlaneIconWithIdAndRotation('vehicle-A',35,getZoomMultiplier())}).motionDuration(collisionConfig.motionDuration)

   carB = L.motion.polyline([...carRoute[1]].reverse(), {
    color: "transparent",
    markerId: "vehicle-B",
    rotation: 35,
    type: 'airplane'
  }, {
    auto: true,
    easing: L.Motion.Ease.swing
  },{icon:movingPlaneIconWithIdAndRotation('vehicle-B',35,getZoomMultiplier())}).motionDuration(collisionConfig.motionDuration);
  carA.getMarker().bindTooltip("<b>Speed:</b> 12km/hr<br><b>License Plate:</b> DL00XX1234",{permanent:true,direction:"top",offset:L.point(0,-45)}).openTooltip();
  carB.getMarker().bindTooltip("<b>Speed:</b> 15km/hr<br><b>License Plate:</b> DL00XX4567",{permanent:true,direction:"top",offset:L.point(0,-45)}).openTooltip();
  setTimeOutToUpdateMarkerTooltip(carA,carB,collisionConfig.speedA+1,collisionConfig.speedB-1,2500);
  setTimeOutToUpdateMarkerTooltip(carA,carB,collisionConfig.speedA+2,collisionConfig.speedB,4500);
  setTimeOutToUpdateMarkerTooltip(carA,carB,collisionConfig.speedA-1,collisionConfig.speedB+2,6500);
  setTimeOutToUpdateMarkerTooltip(carA,carB,collisionConfig.speedA,collisionConfig.speedB,10000);

  setTimeout(() => {
    var marker = carB.getMarker();
    marker.options.type = 'airplane-speed-violation';
    var icon = movingPlaneSpeedViolationIconWithIdAndRotation(carB.options.markerId,carB.options.rotation,getZoomMultiplier());
    marker.setIcon(icon);

  } , 4000);

  rotateMarkerTimeout();

  
  
  carMotionLayer = L.motion.group([carA,carB]).addTo(map);
  carA.once(L.Motion.Event.Ended, (e) => {
    carAFinished = true;
    handleCollision();
  });
  carB.once(L.Motion.Event.Ended, (e) => {
    carBFinished = true;
    handleCollision();
  });
  
}

//Rotate marker timeout
const rotateMarkerTimeout = () => {
  carATimeout = setTimeout(() => {
    var icons = document.getElementById('vehicle-A');
   
    icons.style.transform = " rotate(122deg)";
    carA.options.rotation = 122;
  } , 3800);

  carBTimeout = setTimeout(() => {
    var icons = document.getElementById('vehicle-B');
    icons.style.transform = " rotate(122deg)";
    carB.options.rotation =  122;
    
    carBTimeout = setTimeout(() => {
      var icons = document.getElementById('vehicle-B');
      icons.style.transform = " rotate(215deg)";
      carB.options.rotation = 215;
    } , 1000);
  } , 1000);
  
}

//Handle Collision
const handleCollision = () => {
  console.log("handle collision",carAFinished,carBFinished);
  if(carAFinished && carBFinished){
    var carALatLng = carA.getMarker().getLatLng();
    var carBLatLng = carB.getMarker().getLatLng();
    var from = turf.point([carALatLng.lng, carALatLng.lat]);
    var to = turf.point([carBLatLng.lng, carBLatLng.lat]);
    var options = {units: 'meters'};

    var distance = turf.distance(from, to, options);
    if(distance>5){
      return;
    }
    console.log("Collision");
    if(soundToggle){
      collisionAudio.play();
    }
    collisionLatLng = carA.getMarker().getLatLng();
  
    limitAreaLayer = L.circle(collisionLatLng,100, {color:"red",className:"collision-area"}).addTo(map);
    
    collisionMarkerLayer = L.marker(collisionLatLng,{icon:collisionPlaneIcon});
    collisionMarkerLayer.addTo(map);
    map.flyToBounds(limitAreaLayer.getBounds(),{maxZoom:17});
    addCollision()
    sidebar.setContent(prepareSidebarContent());
    setTimeout(() => {sidebar.show();} , 1500);
    

    carAFinished = false;
    carBFinished = false;
    carA.getMarker().unbindTooltip();
    carB.getMarker().unbindTooltip();
    carA.remove();
    carB.remove();
    carMotionLayer.remove();
  }
}

//Load Car Route GeoJSON
const loadCarRouteGeoJSON = () => {
  new L.GeoJSON.AJAX("/json/Car-Route-LA.geojson",{onEachFeature:function (feature, layer) {
    carRoute.push(layer.getLatLngs());
  }});     
}

//Basemaps
var basemaps = [
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 20,
    minZoom: 0,
    iconURL: '',
    label: 'Street',
  }),
  L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png', {
    maxZoom: 20,
    minZoom: 0,
    iconURL: '',
    label: 'Satellite',
  }),
]

//Reset Map 
const resetScenario = () => {
  removeScenarioLayers();
  sidebar.hide();
  if(carATimeout) clearTimeout(carATimeout);
  if(carBTimeout) clearTimeout(carBTimeout);
  carAFinished = false;
  carBFinished = false;
  limitAreaLayer = null;
  carMotionLayer = null;
  collisionMarkerLayer = null;
  collisionLatLng = null;
}

//Hide Runway Layer
const removeRunwayLayer = () => {
  if(runwayLayer && map.hasLayer(runwayLayer)){
    runwayLayer.remove();
  }
}

//Show Runway Layer
const showRunwayLayer = () => {
  if(runwayLayer && !map.hasLayer(runwayLayer)){
    runwayLayer.addTo(map);
  }
}

//Remove Scenario Layers
const removeScenarioLayers = () => {
  if(limitAreaLayer && map.hasLayer(limitAreaLayer)){
    map.removeLayer(limitAreaLayer);
  }
  if(collisionMarkerLayer && map.hasLayer(collisionMarkerLayer)){
    map.removeLayer(collisionMarkerLayer);
  }
  if(carMotionLayer && map.hasLayer(carMotionLayer)){
   map.removeLayer(carMotionLayer);
  }
}
//Add Scenario Layers
const addScenarioLayers = () => {
  if(limitAreaLayer && !map.hasLayer(limitAreaLayer)){
    map.addLayer(limitAreaLayer);
  }
  if(collisionMarkerLayer && !map.hasLayer(collisionMarkerLayer)){
    map.addLayer(collisionMarkerLayer);
  }
  // if(carMotionLayer && !map.hasLayer(carMotionLayer)){
  //   map.addLayer(carMotionLayer);
  // }
}

//Remove Static Data Layer
const removeStaticDataLayer = () => {
  if(staticDataLayer && map.hasLayer(staticDataLayer)){
    map.removeLayer(staticDataLayer);
  }
}

//Add Static Data Layer
const addStaticDataLayer = () => {
  if(staticDataLayer && !map.hasLayer(staticDataLayer)){
    map.addLayer(staticDataLayer);
  }
}


const moveToHome = ()=>{
  //Move to home
  var homePoint = map.options.crs.latLngToPoint(L.latLng([initMapData.lat,initMapData.lon]),initMapData.zoom);
  if(sidebar.isVisible()){
    homePoint.x = homePoint.x - document.getElementById("sidebar").offsetWidth/2; 
  }
  var homeLatLng = map.options.crs.pointToLatLng(homePoint,initMapData.zoom);
  map.flyTo(homeLatLng, initMapData.zoom,);
  
}

const getZoomMultiplier = () => {
  if(map.getZoom()>=18){
    return 1.75;
  }
  else if(map.getZoom()>=17){
    return 1.25;
  }
  else if(map.getZoom()>=15){
    return 1;
  }
  else if(map.getZoom()>=14){
    return 0.8;
  }
  return 1;
}

//Zoom Listener
const zoomListener = () => {

  
  
  map.on('zoomend', (e) => {
    console.log("zoom listener",map.getZoom());
    if(map.getZoom()>=14){
      showRunwayLayer();
      addScenarioLayers();
      addStaticDataLayer();
      showLoopLayers(map);
    }else{
      removeScenarioLayers();
      removeRunwayLayer();
      removeStaticDataLayer();
      hideLoopLayers(map);
    }
    map.eachLayer(layer=>{
      
      if(layer instanceof L.Marker) {
        var icon = layer.getIcon();
        if(icon.options.iconUrl !== undefined && !icon.options.iconUrl.includes("marker-icon"))
        icon.options.iconSize = [40*getZoomMultiplier(),40*getZoomMultiplier()]
        layer.setIcon(icon);
      }
      if(layer instanceof L.Motion.Polyline){
        
        console.log("layer",layer.options);
        var marker = layer.getMarker();
        var icon;
        switch(layer.options.type){
          case "airplane":
            icon = movingPlaneIconWithIdAndRotation(layer.options.markerId,layer.options.rotation,getZoomMultiplier());
            break;
          case "airplane-speed-violation":
            icon = movingPlaneSpeedViolationIconWithIdAndRotation(layer.options.markerId,layer.options.rotation,getZoomMultiplier());
            break;
          case "fire-truck":
            icon = movingFireTruckIconWithIdAndRotation(layer.options.markerId,layer.options.rotation,getZoomMultiplier());
            break;
          default:
              icon = movingPlaneIconWithIdAndRotation(layer.options.markerId,layer.options.rotation,getZoomMultiplier());

        }
       
        
        marker.setIcon(icon);
      }
    });
  })
}
const homeButton =  L.Control.extend({

    options: {
      position: 'topleft'
    },
  
    onAdd: function (map) {
      var container = L.DomUtil.create('input');
      container.type="button";
      container.title="Home";
      container.value = "";
      container.style.backgroundImage = 'url('+homeIcon+')'; 
      container.className = "custom-control-btn";
      container.onclick = function(){
       moveToHome();
      }
  
      return container;
    }
  });

const sidebarButton =  L.Control.extend({

    options: {
      position: 'topleft'
    },
  
    onAdd: function (map) {
      var container = L.DomUtil.create('input');
      container.type="button";
      container.title="Collision Scenario";
      container.value = "";
      container.style.backgroundImage = 'url('+tableIcon+')'; 
      container.className = "custom-control-btn";
      
      container.onclick = function(){
        sidebar.toggle();
      }
  
      return container;
    }
  });

const soundButton =  L.Control.extend({

    options: {
      position: 'topleft'
    },
  
    onAdd: function (map) {
      var container = L.DomUtil.create('input');
      container.type="button";
      container.title="Sound";
      container.value = "";
      container.style.backgroundImage = 'url('+soundIcon+')'; 
      container.className = "custom-control-btn";
      
      container.onclick = function(){
        if(soundToggle){
            container.style.backgroundImage = 'url('+muteIcon+')'; 
            soundToggle = false;
        }else{
            container.style.backgroundImage = 'url('+soundIcon+')'; 
            soundToggle = true;
        }
      }
  
      return container;
    }
  });



//Load static data from GeoJSON
const loadStaticData = ()=>{
  staticDataLayer = new L.GeoJSON.AJAX("/json/Points-LA.geojson",{style:{color:"#000",weight:2},
  onEachFeature:(feature, layer)=>{
    layer.bindTooltip(feature.properties.TYPE,{direction:"top",opacity:1});
  },
  pointToLayer:(geoJsonPoint, latlng)=>{

   

    switch(geoJsonPoint.properties.TYPE){
      case "Aeroplane":
        return L.marker(latlng,{icon: stopPlaneIcon});
      case "Luggage Car":
        return L.marker(latlng,{icon:L.icon({
          iconUrl: luggageCarGreyIcon,
          iconSize: [40, 40],
      })});
      case "Gangway":
        return L.marker(latlng,{icon:L.icon({
          iconUrl: gangwayIcon,
          iconSize: [40, 40],
      })});
      case "Fire Truck":
        return L.marker(latlng,{icon:L.icon({
          iconUrl: fireTruckGreyIcon,
          iconSize: [40, 40],
      })});

      default:
        return L.marker(latlng);
    }

  }
});      

  staticDataLayer.addTo(map);
}

export {initiateMap,startCarRoute,resetScenario,moveToHome};