import { Button } from "@chakra-ui/button";
import { Box, SimpleGrid } from "@chakra-ui/layout";
import { Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/popover";
import React, { useEffect, useState } from "react";
import { initiateMap, moveToHome, resetScenario, startCarRoute } from "./map-components/map-control";
import layersIcon from "../assets/map/layers.png";
import { Image } from "@chakra-ui/image";
import ScenarioButton from "./scenario-button";
import "./map-components/css/sidebar.css";
import "./map-components/css/leaflet-map.css";
import muteIcon from 'assets/map/mute.png';
import refreshIcon from 'assets/map/refresh.png';

const HomeMap = () =>{
   
    const [toggleLayerControl, setToggleLayerControl] = useState(false);
   
    const [selectedScenario, setSelectedScenario] = useState(0);
    
    useEffect(() => {
        initiateMap();
      }, []);
     
    const scenarioButtonClick = (id)=>{
        resetScenario();
        setSelectedScenario(id);
        if(id===1) setToggleLayerControl(false);
    }



    return (<Box flex="1 1 auto">
        <Box position="absolute" top="70px" right="10px" zIndex="99999" display="flex" flexDir={"column"} gridGap={2}>
        
        <Popover isOpen={toggleLayerControl} placement="bottom-end" closeOnBlur={false} computePositionOnMount="false">
        <PopoverTrigger><Box></Box></PopoverTrigger>
        <PopoverContent bg="rgba(0,0,0,0.8)" width="fit-content" _focus={{outline:"none"}}>
            <PopoverCloseButton onClick={()=>setToggleLayerControl(false)}  h={30} w={30}/>
            <PopoverHeader fontWeight="bold">Simulation</PopoverHeader>
            <PopoverBody>
            <SimpleGrid columns={1} spacing={4}>
                <ScenarioButton onClick={()=>{scenarioButtonClick(1);startCarRoute();}}  bg={selectedScenario===1?"orange.500":"rgba(255, 255, 255, 0.08)"}>Vehicle Collision - Taxiway</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(2)}>Vehicle Collision - Runaway</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(3)}>Vehicle Speeding - Normal Road</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(4)}>Vehicle Trespassing - Geo-Fence</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(5)}>Aircraft on Tow</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(6)}>Vehicle Stalled - Normal Road</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(7)}>Vehicle Stalled - Taxiway</ScenarioButton>
                <ScenarioButton onClick={()=>scenarioButtonClick(8)}>Vehicle Stalled - Runaway</ScenarioButton>
            </SimpleGrid>
            </PopoverBody>
        </PopoverContent>
        </Popover>
            <Button p={3} h="40px" w="40px" opacity={toggleLayerControl?"0":"1"} bg="rgba(0,0,0,0.7)" _hover={{bg:"rgba(0,0,0,0.9)"}} onClick={()=>setToggleLayerControl(true)}><Image color="white" src={layersIcon}></Image></Button>
            <Button p={3} h="40px" w="40px" opacity={toggleLayerControl?"0":"1"} bg="rgba(0,0,0,0.7)" _hover={{bg:"rgba(0,0,0,0.9)"}} onClick={()=>{resetScenario();setSelectedScenario(-1);moveToHome()}}><Image color="white" src={refreshIcon}></Image></Button>

        </Box>
        <Image src={muteIcon} display="none" />
        <div id="sidebar">
            {/* <h5 class="sidebar-heading">Collision Details</h5>
            <div class="crash-details-grid">
                <div class="crash-detail">
                    <span class="crash-detail-label-text">Collision ID: </span> 1
                    <br/>
                    <span class="crash-detail-label-text">Date &amp; Time: </span> 2020-01-01 00:00:00
                    <br/>
                    <span class="crash-detail-label-text">Vehicle 1 License Plate: </span> D00XX1234
                    <br/>
                    <span class="crash-detail-label-text">Vehicle 2 License Plate: </span> D00XX1234
                    <br/>
                    <span class="crash-detail-label-text">At Fault: </span> 1
                    <br/>
                    <span class="crash-detail-label-text">Location: </span> Airstrip 1
                </div>
            </div> */}
        </div>
        <div id="map" style={{height:"100%"}}></div>
    </Box>);
}


export default HomeMap;