import { Box, Flex, Text } from '@chakra-ui/layout';
import profile from 'assets/img/navbar/profile.png';
import logo from 'assets/img/navbar/logo.png';
import { Image } from '@chakra-ui/image';
import { Avatar } from '@chakra-ui/avatar';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Portal } from '@chakra-ui/portal';
import { useDisclosure } from '@chakra-ui/hooks';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      alignContent="center"
      p={2}
      boxShadow="lg"
      zIndex={999}
      color={'white'}
      h="60px"
      bg="black"
    >
      <Image mt={1} w={280} objectFit="cover" src={logo} alt="Logo" cursor="pointer" />
     
     
      <Menu isOpen={isOpen} onClose={onClose} closeOnBlur="true">
        <MenuButton onClick={onOpen} >
        <Box  d="flex" alignItems="center"> 
        <Text fontSize='lg' mr={3}>User Name</Text>
        <Avatar mr={2} h="40px" w="40px" name="User Name" transition="all 0.2s" src={profile} p={1} bg="white" cursor="pointer" />
        
        
        </Box>
        
        </MenuButton>
        <Portal>
          <MenuList zIndex="99999" bg="rgba(0,0,0,0.8)" color="white">
            <MenuItem>Profile</MenuItem>
            <MenuItem>Settings</MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    
      
      
    </Flex>
  );
};

export default Navbar;
