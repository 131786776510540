import { Box } from '@chakra-ui/layout';
import Navbar from 'components/Navbar';
import HomeMap from 'components/HomeMap';

const HomeScreen = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Navbar/>
      <HomeMap/>
    </Box>
  );
};

export default HomeScreen;
